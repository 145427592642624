<template>
  <div class="scanCodeSignProcedure">
    <div class="procedure-header">
      <div class="ordinary-head">
        <img class="topbg" src="@/assets/downLoad/bg.png" alt="">
        <img class="headBg" src="@/assets/downLoad/logo.png" alt="">
        <img class="downloadNow" src="@/assets/downLoad/mainText.png" alt="">
        <div class="p-operaArea">
          <span @click="downEvent">立即下载</span>
          <!-- <span @click="openApp">打开智汇学堂 ></span> -->
        </div>
      </div>
      <!-- <img src="@/assets/img/signIn/topBanner.png" alt=""> -->
    </div>
    <div class="procedure-cont">
      <div class="pc-title">
        <img src="@/assets/img/signIn/s1.png" alt="">
        <span>扫码签到流程</span>
        <img src="@/assets/img/signIn/s2.png" alt="">
      </div>
      <div class="step-item">
        <div class="s-item">
          <div><span>1</span><span>第一步</span></div>
          <div>点击我的页面</div>
        </div>
        <img class="s-right f1" src="@/assets/img/signIn/firstStep.png" alt="">
      </div>
      <div class="step-item fre">
        <div class="s-item">
          <div><span>2</span><span>第二步</span></div>
          <div>点击扫一扫</div>
        </div>
        <img class="s-right f2" src="@/assets/img/signIn/secondStep.png" alt="">
      </div>
      <div class="step-item">
        <div class="s-item">
          <div><span>3</span><span>第三步</span></div>
          <div>扫码后点击签到</div>
        </div>
        <img class="s-right f3" src="@/assets/img/signIn/thirdStep.png" alt="">
      </div>
    </div>
    <div style="height: 140px;"></div>
    <pagetips ref="pagetips"></pagetips>
  </div>
</template>

<script>
import pagetips from '../commonModule/pagetips'
export default {
  name: 'scanCodeSignProcedure',
  components: {
    pagetips
  },
  data () {
    return {
      type: null,
      downurls: null,
      iosAPPlink: 'https://api.zhihuischool.cn/.well-known/apple-app-site-association',
      androidAPPlink: 'android://com.czh.zhihuischool/open'
    }
  },
  mounted () {
    // alert(this.$store.state.deviceType)
    const type = this.$store.state.deviceType == 1 ? 2 : 1
    this.type = this.$store.state.deviceType
    if (this.$store.state.deviceType < 4) {
      this.getNewVersionEvent(type)
    } else {
      // this.downurls = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.czh.zhxt'
      // console.log(this.$refs.pagetips);
      // this.$refs.pagetips.showEvent();
    }
  },
  methods: {
    getNewVersionEvent (type) {
      this.$request.getNewVersion({ type }).then(res => {
        if (res.data.code == 0) {
          this.downurls = res.data.data.downurls
        }
      })
    },
    downEvent () {
      if (!this.downurls) {
        this.Info('暂时无法下载')
        return
      }
      // window.location.href = this.downurls;
      window.location.replace(this.downurls)
    },
    openApp () {
      // alert(this.type)
      if (this.type == 1) {
        window.location.href = this.iosAPPlink
      } else if (this.type == 2) {
        window.location.href = this.androidAPPlink
      } else if (this.type == 4 || this.type == 5 || this.type == 6 || this.type == 7) {
        // this.Info('选择浏览器打开该页面,再进行app打开');
        this.$refs.pagetips.showEvent()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .scanCodeSignProcedure {
    height: 100%;
    overflow: scroll;
    .procedure-header {
      position: relative;
      .ordinary-head {
        width: 100vw;
        height: 41.15vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: #0076dd;
        padding-bottom: 60px;
        .topbg{
          width: 100%;
          position: absolute;
          top: 0;
          z-index: -1;
        }
        .headBg {
          width: 32vw;
          margin: 5.17vh auto 2.65vh;
        }
        .downloadNow {
          width: 60vw;
          margin: 0 auto;
        }
        .p-operaArea {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2vh;
          margin-top: 5vh;
          &>span:nth-of-type(1) {
            display: inline-block;
            width: 253px;
            height: 52px;
            background: #FFFFFF;
            border-radius: 5px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #1A7EE2;
            text-align: center;
            line-height: 52px;
          }
          &>span:nth-of-type(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
    .procedure-cont {
      box-sizing: border-box;
      // padding: 36px 20px 0 45px;
      padding: 36px 0 0;
      background: linear-gradient(180deg, #EDF4FF 0%, #FFFFFF 100%);
      .pc-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 31px;
        img {
          width: 57px;
          height: 1px;
        }
        span {
          font-size: 18px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #1A7EE2;
          margin: 0 20px;
        }
      }
      .step-item {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px 0 45px;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
        .s-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          & > div:nth-of-type(1) {
            & > span:nth-of-type(1) {
              display: inline-block;
              width: 20px;
              height: 20px;
              background: #1A7EE2;
              font-size: 16px;
              color: #FFFFFF;
              border-radius: 50%;
              text-align: center;
              line-height: 20px;
              margin-right: 9px;
            }
            & > span:nth-of-type(2) {
              font-size: 16px;
              color: #1A7EE2;
              border-bottom: 3px solid rgba(26, 126, 226, .2);
            }
          }
          & > div:nth-of-type(2) {
            margin-left: 29px;
            margin-top: 24px;
            font-size: 16px;
            color: #363840;
          }
        }
        .s-left {

        }
        .f1 {
          width: 133px;
          height: 141px;
        }
        .f2 {
          width: 145px;
          height: 141px;
        }
        .f3 {
          width: 135px;
          height: 141px;
        }
      }
      .fre {
        flex-direction: row-reverse;
        .s-item {
          margin-right: 36px;
        }
      }
    }
  }
</style>
